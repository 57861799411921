import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import logoImage from './getpcLogo.png';
// import Loginpage from '../../Login/Loginpage';

function NavBar() {

    // const navigate = useNavigate();
    // function toLoginpage(){
    //     navigate('/Loginpage');
    // }

    // const [activePage, setActivePage] = useState(() => localStorage.getItem("activePage") || 1);

    // useEffect(() => {
    //     localStorage.setItem("activePage", activePage);
    // }, [activePage]);

    // const handleNavigateToInputForm = () => {
    //     setActivePage("1");
    // };
    // const handleNavigateToListView = () => {
    //     setActivePage("2");
    // };

    // const handleNavigateToGridView = () => {
    //     setActivePage("3");
    // };

    // const handleNavigateToNutrition = () =>{
    //     setActivePage("4");
    // }

    // const [show, setShow] = useState(false);
    // const [builds, setBuilds] = useState(false);
    // const showDropdown = (e) => {
    //     setShow(!show);
    //     setBuilds(!show);
    // }
    // const hideDropdown = (e) => {
    //     setShow(false);
    //     setBuilds(false);
    // }

    return (

        <div className='nav'>
            <Navbar expand="lg" className="bg-body-tertiary container position-static p-0">
                <Container className='navinner p-0'>
                    <Navbar.Brand href="/"><img className="imgNv" src={logoImage} alt='logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav
                            //  defaultActiveKey={activePage}
                            //     onSelect={(selectedKey, event) => {
                            // event.preventDefault();
                            // console.log("selected key is: ", selectedKey);
                            // setActivePage(selectedKey);}}
                            className="navItems ms-auto">
                            <Nav.Link className='navItem mx-3' href="/">Home</Nav.Link>
                            <Nav.Link className='navItem mx-3' href="/about">About</Nav.Link>
                            <NavDropdown title="Our Builds" id="basic-nav-dropdown" className='navItem mx-3' >
                                <NavDropdown.Item href="/gaming" id='nav-sub-Item'>Gaming</NavDropdown.Item>
                                <NavDropdown.Item href="/Engineering" id='nav-sub-Item'>Engineering & Design</NavDropdown.Item>
                                <NavDropdown.Item href="" id='nav-sub-Item'>Content Creation</NavDropdown.Item>
                            </NavDropdown>
                            {/* <Nav.Link className='navItem mx-3' href="/portfolio">Portfolio</Nav.Link> */}
                            <Nav.Link className='navItem mx-3' href="/blog">Blog</Nav.Link>
                            {/* <NavDropdown title="More" id="basic-nav-dropdown" className='navItem mx-3' >
                                <NavDropdown.Item href="/blog" >Blogs</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio" >Portfolio</NavDropdown.Item>                                
                            </NavDropdown> */}
                            <Nav.Link className='navItem mx-3' href="/contact">Contact</Nav.Link>
                            <Nav.Link className='navItem mx-3' href="/login"><i class="fa-regular fa-user"></i></Nav.Link>
                        </Nav>
                        {/* <Button className='btnn mx-3 px-5'>Book Now</Button> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>
    );
}
export default NavBar;