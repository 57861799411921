import React, { useState, useEffect } from "react";
import vec1 from './vec1.png';
// import img1 from './img1.svg';
// import img2 from './img2.svg';
// import Texticon from './Texticon.svg';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Button } from 'react-bootstrap';
// import OwlCarousel from 'react-owl-carousel';
import Enquire from '../HomeSec2/Enquire';
import Slider from "react-slick";
// import reviewData from "./ReviewData";
// import AOS from 'aos';
// import 'aos/dist/aos.css';


function Reviews(props) {

  const [seen, setSeen] = useState(false)

    function togglePop () {
        setSeen(!seen);
    };    

  // const options = {
  //   margin: 30,
  //   responsiveClass: true,

  //   dots: true,
  //   autoplay: true,
  //   // navText: ["Prev", "Next"],
  //   smartSpeed: 1000,
  //   responsive: {
  //     0: {
  //       items: 1,
  //       nav: true,
  //     },
  //     767: {
  //       items: 2,
  //       nav: true,
  //     },
  //     1000: {
  //       items: 3,
  //       nav: true
  //     }
  //   },
  // };
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    // responsive: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      
    ]
  };

//   useEffect(() => {
//     AOS.init();
//     AOS.refresh();
//   }, [])


  return (
    <section className="Reviewp">
      <Container>
        <span id="sp2">What our customers are saying. . . .</span>
          <div className="w-3/4 m-auto">
            <div className="mt-5">
            <Slider {...settings}>
              {props.content.map((value) => (
                <div className="">
                  <div className=" review-contain bg-white text-black justify-center items-center gap-4 p-5">
                    <p>{value.text}</p>
                    <h5>{value.name}</h5>
                    <div className="stars">
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                      </div>
                  </div>
                </div>
              ))}
              </Slider>
            </div>
          </div>
            {/* <OwlCarousel autoplayHoverPause={true} loop={true} nav={true} dots={true} {...options} className="Rowl"> */}
              
              {/* <div>
                <div className="contain5">
                {props.content.map((val) => (
                  <div key={val.id}><div className="customerR">
                    <p>{val.text}</p>
                  </div><div className="wave">
                      <h5>{val.name}</h5>
                      <p>Lorem Ipsum</p>
                      <div className="stars">
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                        <span>
                          <img src={vec1} alt="" />
                        </span>
                      </div>
                    </div></div>
                ))}
                </div>
              </div> */}

              {/* <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                    
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                    
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                   
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                    
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                    
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="contain5">
                  <div className="customerR">
                    <p>is simply dummy text of thprinting <br /> and typesetting ndustry.Loremsss <br /> Ipsumddc has been the industry's <br /> stand arddxdcd dummy text ever <br /> sinceisss simply   .
                    </p>
                  </div>
                  <div className="wave">
                    
                    <h5>Rachel Doe</h5>
                    <p>Lorem Ipsum</p>
                    <div className="stars">
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                      <span>
                        <img src={vec1} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </OwlCarousel> */}

            <div className="review pt-5">
              <div className="d-flex justify-content-center align-items-center gap-2 ">
              <Button className='connect-btn  px-5' onClick={togglePop} >Connect Now</Button>
              {seen ? <Enquire toggle={togglePop} /> : null}
              </div>
            </div>
          {/* </Container> */}
        {/* </div> */}
      </Container>
    </section>
  );
}
export default Reviews;