const reviewData= [
    {
        text: "I’ve been building PCs for years, but the level of expertise and quality at GETPc is unmatched. They helped me build the gaming rig of my dreams!",
        name: "Amit Gupta"
    },
    {
        text: "I’ve been building PCs for years, but the level of expertise and quality at GETPc is unmatched. They helped me build the gaming rig of my dreams!",
        name: "Priya Singh"
    },
    {
        text: "I’ve been building PCs for years, but the level of expertise and quality at GETPc is unmatched. They helped me build the gaming rig of my dreams!",
        name: "Priya Singh"
    },
    {
        text: "I’ve been building PCs for years, but the level of expertise and quality at GETPc is unmatched. They helped me build the gaming rig of my dreams!",
        name: "Rakesh Mittal"
    },
    {
        text: "I’ve been building PCs for years, but the level of expertise and quality at GETPc is unmatched. They helped me build the gaming rig of my dreams!",
        name: "Farah Ali"
    },
    {
        text: "I’ve been building PCs for years, but the level of expertise and quality at GETPc is unmatched. They helped me build the gaming rig of my dreams!",
        name: "Amit Gupta"
    }
    
]
export default reviewData;