import img1 from './MSI_R_320.png';


const Valorant_Data= [
    {
        img: img1,
        model: "MODEL 1",
        cpu: "Core i5 14400F",
        m_b: "MSI B760M bomber wifi DDR5",
        gpu: "MSI 4060 ti 8 GB",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹125,000",
        old_price: "₹125,000"
    },
    {
        img: img1,
        model: "MODEL 2",
        cpu: "Core i5 14400F",
        m_b: "MSI B760M bomber wifi DDR5",
        gpu: "MSI 4060 8 GB",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹99,999",
        old_price: "₹99,999"
    }
    
]
export default Valorant_Data;