import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../../Home/HomeSec1/Navbar";
import BottomPage from "../../Home/HomeLastSec/Bottompage";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

function NewPassword(){

    // useEffect(() => {
    //     AOS.init();
    //     AOS.refresh();
    //     // AOS.init({
    //     //     duration: 1200,
    //     //   })
    // }, [])

    return(
        <section className="Passwordpage">
            <NavBar/>
            <Container className="box1 " data-aos='fade-down'>
                <Row className="">
                    <Col className="Ccol py-5 " xl={6}>
                        {/* <img src={formimg} alt="" className="imgform" /> */}
                    </Col>
                    <Col className="Overlay2 p-5 my-4" xl={6}>
                        <h4 id="login-head">Forgot Password ?</h4>
                        <p>No worries! we'll send you reset instruction</p>
                        <br /><br />
                        <form className="d-flex flex-column align-items-left">

                            <label type="email" value="Email">Email ID</label>
                            <input type="email" />

                            <label type="code" >Verification code</label>
                            <input type="code" />

                            <div className="btnn1 mt-3">
                                <button type="submit" className="btnn4">Submit</button>
                            </div>
                            <div className="my-4">
                                <p>I already have an account<Link>log in</Link></p>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
            <BottomPage/>
        </section>
    );
}
export default NewPassword;