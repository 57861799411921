import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import img1 from './image2.png';
import img2 from './image3.png';
import img3 from './image4.png';
import img4 from './strategy.png';
import img5 from './image5.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Offering() {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <section className="Offp">
            <div className="contain2 container-fluid">
                <span id="sp3">WHAT WE ARE<br />OFFERING</span>
                <Row className="contain21">
                    <Col className="Offp-contain" data-aos="flip-right">
                        <img src={img1} alt="Off image" />
                        <h5>100% Genuine Products</h5>
                        <p>We use only premium components from trusted brands to ensure your custom PC delivers unparalleled performance and durability.
                            Whether it’s the latest processors, graphics cards, or storage solutions, we have it all.</p>
                    </Col>

                    <Col className="Offp-contain" data-aos="flip-right">
                        <img src={img4} alt="Off image" />
                        <h5>Expert Consultation</h5>
                        <p>Our team of experts is here to guide you through every step of the customization process. From selecting the right components to optimizing performance,
                            we provide personalized advice to ensure you get the best out of your custom PC.</p>
                    </Col>

                    <Col className="Offp-contain" data-aos="flip-right">
                        <img src={img3} alt="Off image" />
                        <h5>3 Year Standard Warranty</h5>
                        <p>We stand by the quality of our builds. That’s why every custom PC comes with a comprehensive warranty, giving you peace of mind
                            and assurance that your investment is protected.</p>
                    </Col>

                    <Col className="Offp-contain" data-aos="flip-right">
                        <img src={img5} alt="Off image" />
                        <h5>Stress Tested System</h5>
                        <p>At GET PC, we've rigorously stress-tested our systems to ensure they can handle the most demanding tasks with ease.
                            Our PCs undergo extensive testing to verify their performance, stability, and reliability under extreme conditions.</p>
                    </Col>

                    <Col className="Offp-contain" data-aos="flip-right">
                        <img src={img2} alt="Off image" />
                        <h5>Lifetime Test Support</h5>
                        <p> Whether it's routine maintenance, troubleshooting, or performance evaluations, our dedicated team ensures your PC remains in peak condition.
                            With GET PC, you receive not just a product but a reliable partner in long-term performance and support.</p>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
export default Offering;