import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Enquire from "../../../Home/HomeSec2/Enquire";

function LeonardoSeries(props) {

    const [seen, setSeen] = useState(false)

    function togglePop() {
        setSeen(!seen);
    };

    return (
        <section className="prod-content">
            <span id="sp2">Picasso Series</span>
            <div className="prod-list row row-cols-2 gx-5 gy-4 mt-3">
                {props.content.map((value) => (
                    <div className="prod-prop">
                        <div className="prod-box col">
                            <div className="prod-items gap-3">
                                <img src={value.img} width="100%" height="450px" />
                                <div>
                                <h3 className="mb-5">{value.model}</h3>
                                <div className="d-flex gap-4">
                                    <div>
                                        <div>
                                            <h4>CPU</h4>
                                            <p>{value.cpu}</p>
                                        </div>
                                        <div>
                                            <h4>GPU</h4>
                                            <p>{value.gpu}</p>
                                        </div>
                                        <div className="price-tag d-flex mt-4">
                                        <h3>{value.new_price}</h3>
                                        {/* <p className="prev-price">{value.old_price}</p> */}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h4>MOTHER BOARD</h4>
                                            <p>{value.m_b}</p>
                                        </div>
                                        <div>
                                            <h4>RAM</h4>
                                            <p>{value.ram}</p>
                                        </div>
                                        {/* <Button className='prod-btn  px-4 mt-2' onClick={togglePop} >Enquire Now</Button>
                                        {seen ? <Enquire toggle={togglePop} /> : null} */}
                                        <Button className='prod-btn  px-4 mt-2' href='https://wa.me/919259568740' >Enquire Now</Button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
export default LeonardoSeries;    