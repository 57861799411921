import React, {useState} from "react";
import { Container, Button } from "react-bootstrap";
import Enquire from '../../Home/HomeSec2/Enquire';

function Contact1() {

    const [seen, setSeen] = useState(false)

    function togglePop () {
        setSeen(!seen);
    };

    return (
        <section className="Contact1p">
            <Container>
                    <div className="contact1-contain">
                        <span id="sp1">TALK TO US</span>
                        <p>At GetPC, we craft custom workstations designed just for you—think of it as a bespoke suit for your computer! Our expert team is here to help you create the perfect setup that matches your unique requirements. We offer a free consultation to discuss your needs and preferences, with no pressure and no commissions. Reach out to us via phone, email, or visit any of our stores to get started. Let’s build the ideal computer for you!</p>
                        <Button className='btnn  px-5' onClick={togglePop} >Enquire Now</Button>
                            {seen ? <Enquire toggle={togglePop} /> : null}
                    </div>
            </Container>
        </section>
    );
}
export default Contact1;