import img1 from './MSI_Velox.png';
import img2 from './Zenwood.png';


const Leonardo_Data= [
    {
        img: img1,
        model: "MODEL 1",
        cpu: "Core i9 14900k",
        m_b: "MSI Z790 TOMAHAWK",
        gpu: "MSI RTX 4080 Super",
        ram: "32 x 2 ADATA DDR 5",
        new_price: "₹315,000",
        old_price: "₹345,000"
    },
    {
        img: img2,
        model: "MODEL 2",
        cpu: "Core i7 14700k",
        m_b: "MSI Z790 TOMAHAWK",
        gpu: "MSI 4070 ti Super",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹229,000",
        old_price: "₹239,000"
    }
    
]
export default Leonardo_Data;