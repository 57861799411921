import React from "react";
import NavBar from "../../Home/HomeSec1/Navbar";
import BottomPage from "../../Home/HomeLastSec/Bottompage";
import Wukong from "./Wukong/WukongSeries";
import GOW from "./God_of_War/GOWSeries";
import Valorant from "./Valorant/ValorantSeries";
import Wukong_Data from "./Wukong/Wukong_Data";
import GOW_Data from "./God_of_War/GOW_Data";
import Valorant_Data from "./Valorant/Valorant_Data";


function Gaming(){
    return(
        <section className="EngPage">
            <NavBar/>
            <div className="Eng-content">
            <span id="sp3">Gaming</span>
            <Wukong content = {Wukong_Data}/>
            <GOW content = {GOW_Data} />
            <Valorant content = {Valorant_Data} />
            </div>
            <BottomPage/>
        </section>
    );
}
export default Gaming;