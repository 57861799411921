import React from "react";
import NavBar from "../../Home/HomeSec1/Navbar";
// import ProductContent from "./ProductSec2/Eng&Des";
import BottomPage from "../../Home/HomeLastSec/Bottompage";
// import Product_Data from './ProductSec2/Product_Data';
import Leo from './Leonardo/LeonardoSeries';
import Leonardo_Data from './Leonardo/Leonardo_Data';
import Picasso from './Picasso/PicassoSeries';
import Picasso_Data from './Picasso/Picasso_Data';
import VanGogh from './Van_Gogh/VanGoghSeries';
import VanGogh_Data from './Van_Gogh/VanGogh_Data';

function EngAndDes(){
    return(
        <section className="EngPage">
            <NavBar/>
            <div className="Eng-content">
            <span id="sp3">Engineering & Design</span>
            {/* <ProductContent content = {Product_Data}/> */}
            <Leo content = {Leonardo_Data} />
            <Picasso content = {Picasso_Data} />
            <VanGogh content = {VanGogh_Data} />
            </div>
            <BottomPage/>
        </section>
    );
}
export default EngAndDes;