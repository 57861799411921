import React from "react";
import NavBar from "../Home/HomeSec1/Navbar";
import Bottom from '../Home/HomeLastSec/Bottompage';
import SignIn from './LoginSec1/SignIn';
import {Helmet} from 'react-helmet';


function Loginpage(){
    return (
        <div>
            <Helmet>
                <title>Login to GET PC</title>
            </Helmet>
            <NavBar/>
            <SignIn/>
            <Bottom/>
        </div>
    );
}
export default Loginpage;