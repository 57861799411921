import React, {useState} from 'react';
import { Carousel, Button, Container, Row, Col } from 'react-bootstrap';
import img1 from './gaming2.jpg';
import img2 from './keyboard.jpg';
import img3 from './amdcard.avif';
import Enquire from '../HomeSec2/Enquire';




function Index() {

    const [seen, setSeen] = useState(false)

    function togglePop () {
        setSeen(!seen);
    };  

    return (
        <section className="Servp">
            <Carousel className='caro2'>

                <Carousel.Item className='caro21' interval={3000}>
                    <img src={img1} alt='Service image' />
                    <div className='overlay Overlay1'>
                        <Container>
                            <div className='text1'>
                                <span id='sp1'>OUR SERVICES</span>
                                <div>
                                    <h3>Custom Made PCs</h3>
                                    <p>From ultra-fast processors to high-performance graphics cards, we create gaming, Editing and Trading PCs that deliver an immersive experience like no other.</p>
                                    <Button className='service-btn  px-5' onClick={togglePop} >Enquire Now</Button>
                                    {seen ? <Enquire toggle={togglePop} /> : null}
                                </div>
                            </div>
                        </Container>
                    </div>
                </Carousel.Item>

                <Carousel.Item className='caro21' interval={3000}>
                    <img src={img2} alt='Service image' />
                    <div className='overlay Overlay1'>
                        <Container>
                            <div className='text1'>
                                <span id='sp1'>OUR SERVICES</span>
                                <div>
                                    <h3>Upgrade & Maintenance</h3>
                                    <p>Keep your PC running at its best with our upgrade and maintenance services. From hardware upgrades to routine maintenance, we ensure your machine stays in peak condition.</p>
                                    <Button className='service-btn  px-5' onClick={togglePop} >Enquire Now</Button>
                                    {seen ? <Enquire toggle={togglePop} /> : null}
                                </div>
                            </div>
                        </Container>
                    </div>
                </Carousel.Item>

                <Carousel.Item className='caro21' interval={3000}>
                    <img src={img3} alt='Service image' />
                    <div className='overlay Overlay1'>
                        <Container>
                            <div className='text1'>
                                <span id='sp1'>OUR SERVICES</span>
                                <div>
                                    <h3>Unmatched After Sale Service</h3>
                                    <p>Experience unmatched after-sale service with expert support, fast resolutions, and dedicated care, ensuring your custom PC remains top-notch.</p>
                                    <Button className='service-btn  px-5' onClick={togglePop} >Enquire Now</Button>
                                    {seen ? <Enquire toggle={togglePop} /> : null}
                                </div>
                            </div>
                        </Container>
                    </div>
                </Carousel.Item>

            </Carousel>
        </section>
    );

}
export default Index;