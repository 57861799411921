import img1 from './MSI_Velox.png';
import img2 from './Zenwood.png';


const Wukong_Data= [
    {
        img: img1,
        model: "MODEL 1",
        cpu: "Core i9 14900k",
        m_b: "MSI Z790 Gaming Plus Wifi",
        gpu: "MSI RTX 4080 Super",
        ram: "32 x 2 ADATA DDR 5",
        new_price: "₹299,000",
        old_price: "₹299,000"
    },
    {
        img: img2,
        model: "MODEL 2",
        cpu: "Ryzen 7 - 7800X3D",
        m_b: "B650 Edge WIFI",
        gpu: "MSI 4070 ti Super",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹229,000",
        old_price: "₹240,000"
    }
    
]
export default Wukong_Data;