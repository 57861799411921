import React from "react";
import img1 from '../HomeSec7/instagram.png';
import img2 from '../HomeSec7/LinkedIn.png';
import img3 from '../HomeSec7/Facebook.png';
import img4 from '../HomeSec7/Twitter.png';


function SocialMed(){
    return(
        <section className="socialMedia">
        <span id="sp3">FOLLOW US ON</span>
        <div className="smedia-contain">
            <div className=" col" >
                <a href="https://www.instagram.com/getpc.co.in?igsh=MW9kcHl1ZjEzdzJxZA==" >
                <img src={img1} alt="social" />
                <p>Instagram</p>
                </a>
            </div>
            <div className="col">
                <a href="https://www.linkedin.com/company/getpc/" >
                <img src={img2}  alt="social" />
                <p>LinkedIn</p>
                </a>
            </div>
            <div className="col">
                <a href="https://www.facebook.com/profile.php?id=61561973894554">
                <img src={img3}  alt="social" />
                <p>Meta</p>
                </a>
            </div>
            <div className="col">
                <a href="">
                <img src={img4}  alt="social" />
                <p>Twitter</p>
                </a>
            </div>
        </div>
    </section>
    );
}
export default SocialMed;