import React from "react";
import Navbar from '../../../src/components/Home/HomeSec1/Navbar';
import Bottompage from '../../../src/components/Home/HomeLastSec/Bottompage';
import Blog from "./BlogSec1/Blog";
import BlogData from './BlogSec1/BlogData';
import {Helmet} from 'react-helmet';

function Blogpage(){

    return(
        <div className="Blogp">
            <Helmet>
                <title>Blogs | GET PC</title>
            </Helmet>
            <Navbar/>
            <Blog content = {BlogData} />
            <Bottompage/>
        </div>
    );
}
export default Blogpage;