import React, { useState, useEffect } from "react";
// import formimg from './formimg3.png';
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import NavBar from "../../Home/HomeSec1/Navbar";
import BottomPage from "../../Home/HomeLastSec/Bottompage";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {doc, setDoc} from 'firebase/firestore';
import { Helmet } from "react-helmet";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

function Signup() {

    // useEffect(() => {
    //     AOS.init();
    //     AOS.refresh();
    //     // AOS.init({
    //     //     duration: 1200,
    //     //   })
    // }, [])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            if(user){
                await setDoc(doc(db, "Users", user.uid), {
                    email: user.email,
                    name: name,
                    phone: phone,
                    city: city

                });
            }
            alert("Account Created!");
          } catch (err) {
            // console.log(err)
            alert("Fill In The Details");
        }
    }


    return (
        <section className="Signuppage">
            <Helmet>
                <title>Sign Up to GET PC</title>
            </Helmet>
            <NavBar />
            <Container className="box1 " >
                <Row className="">
                    <Col className="Ccol py-5 " xl={6}>
                        {/* <img src={formimg} alt="" className="imgform" /> */}
                    </Col>
                    <Col className="Overlay2 p-5 my-4" xl={6}>
                        <h4 id="login-head">Create an account</h4>
                        <p>Please enter your signup details</p>
                        <br /><br />
                        <form className="d-flex flex-column align-items-left"
                            onSubmit={handleSubmit}>

                            <p><hr />Sign Up<hr /></p>

                            <label type="name" >Full Name*
                            <input 
                             type="text"
                             name="name"
                             onChange={(e) => setName(e.target.value)}
                             value={name}
                             placeholder="eg. Alex"
                              />
                            </label>

                            <label type="email" value="Email">
                                Email ID*
                            <input
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            </label>
                            <label>
                                Phone Number*
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="eg. 9401188391"
                                    value={phone}
                                    required />
                            </label>
                            <label>
                                City*
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="Your City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required></input>
                            </label>

                            <label type="password" >Password*
                            <input
                                type="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                            </label>

                            <label type="password" >Confirm Password*
                            <input
                                type="password"
                                name="confirm_password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                            </label>

                            <div className="btnn1 mt-3" >
                                <Button type="submit" className="btnn4" height="70px">Register</Button>
                            </div>
                            <div className="my-4">
                                <p>I already have an account<Link to='/Loginpage'>log in</Link></p>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
            <BottomPage />
        </section>
    );
}
export default Signup;