import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import img1 from './image1.jpg';
import img2 from './getpcGIF2.gif';

function WhyUs(){
    return(
        <section className="Usp">
            <Container>
                
                <Row className="contain1">
                    <Col className="contain1-text">
                        <span id="sp2">Why Us?</span>
                        <p>At GETPc, we believe that every PC should be as unique as its owner. Whether you’re a hardcore gamer, a creative professional, 
                        a trader or a tech enthusiast, we offer a personalized experienceto help you build the perfect machine tailored to your specific
                        needs. Our custom PCs are crafted with precision, using only the highest quality components to ensure top-notch performance and reliability.</p>
                    </Col>
                    <Col className="contain1-image">
                        <img src={img2} alt="Why Us" />
                    </Col>
                </Row>
                <div className="blob"></div>
            </Container>
        </section>
    );
}
export default WhyUs;