import img1 from './CRYSTAL_X6.png';


const GOW_Data= [
    {
        img: img1,
        model: "MODEL 1",
        cpu: "Ryzen 7 - 7800X3D",
        m_b: "B650 Edge WIFI",
        gpu: "MSI 4070 Super",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹199,999",
        old_price: "₹199,999"
    },
    {
        img: img1,
        model: "MODEL 2",
        cpu: "Ryzen 5 - 9600x upto 5.4 GHz",
        m_b: "B650 Edge WIFI",
        gpu: "MSI 4060 ti 8 GB",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹149,000",
        old_price: "₹149,000"
    }
    
]
export default GOW_Data;