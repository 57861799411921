import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Container} from 'react-bootstrap';
// import Searchicon from './searchicon.svg';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

function Blog(props){

    // useEffect(() => {
    //     AOS.init();
    //     AOS.refresh();
    //     // AOS.init({
    //     //     duration: 1200,
    //     //   })
    //   }, [])

    const navigate = useNavigate();
    function toBlogDesc(){
        navigate('/BlogContent');
    }

    return(
        <section className='Blogpage'> 
        <Container className='blogs'>
            <div>
                <div className='Blogcontain container-fluid'>
                    <div className='d-flex justify-content-center'>
                    <span id='sp1' >Our Blogs</span>
                    {/* <div className='SearchBar ms-auto'>
                        <input type='search' placeholder='Search here for blogs'/>
                        <img src={Searchicon} alt='' />
                    </div> */}
                    </div>
                    <p>
                    The Latest Gaming PC News, Offers, Announcements, and More From Get PC!
                    </p>
                </div>
                <div className='Blogcontent row row-cols-3'>
                    {props.content.map((value,i) => (
                        <div className='col p-1 mb-5' width="32px" onClick={toBlogDesc}>
                            <img src={value.img} alt=''className='m-4' />
                            <h4 className='px-4'>{value.title}</h4>
                            <p className='blogpara px-4'>{value.date}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
        </section>
    );
}
export default Blog;