import React from "react";
import NavBar from "../Home/HomeSec1/Navbar";
import Bottom from '../Home/HomeLastSec/Bottompage';

function Servicepage(){
    return(
        <div>
            <NavBar/>
            <h2 className="nonetext">Work Under Progress</h2>
        </div>
    );
}
export default Servicepage;