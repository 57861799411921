import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {auth} from '../../firebase';
import { signInWithEmailAndPassword} from 'firebase/auth';
// import formimg from './formimage2.png';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

function Login1() {

    // useEffect(() => {
    //     AOS.init();
    //     AOS.refresh();
    //     // AOS.init({
    //     //     duration: 1200,
    //     //   })
    // }, [])

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try{
            await signInWithEmailAndPassword(auth, email, password);
            alert("Account Created!");
        } catch(error){
            // console.log(err)
            alert("**Not Registered**");
        }
    };

    return (
        <section className="loginpage">
            <div >
            <Container className="box1">
                <Row className="">
                    <Col className="Ccol py-5 " xl={6}>
                        {/* <img src={formimg} alt="" className="imgform" /> */}
                    </Col>
                    <Col className="Overlay2 p-5" xl={6}>
                        <h4 id="login-head">Welcome Back!</h4>
                        <p>Please enter your login details</p>
                        <br /><br />
                        <form className="d-flex flex-column align-items-left" 
                         onSubmit={handleSubmit}
                         >

                            <p><hr />Login with Email<hr /></p>

                            <label type="email" value="Email">Your Email*</label>
                            <input 
                             type="text"
                             id="email"
                             name="email"
                             value={email}
                             onChange={(e) => setEmail(e.target.value)}
                             placeholder="myname@example.com"
                              />

                            <label type="password" >Your Password*</label>
                            <input 
                             type="password"
                             id="password"
                             name="password"
                             value={password}
                             onChange={(e) => setPassword(e.target.value)}
                             />

                            <div className="d-flex align-items-center my-4">
                                <input type="checkbox"/><span className="check-text">  Keep me logged in</span>
                                {/* <p className="ms-auto"><Link to='/NewPassword'>Forgot password</Link></p> */}
                            </div>
                            <div className="btnn1" >
                                <Button type="submit" className="btnn4" 
                                height="70px">Login</Button>
                            </div>
                            <div className="my-4">
                                <p>Don't have an account yet?<Link to='/SignUp'>Sign Up</Link></p>
                            </div>
                        </form>

                    </Col>
                </Row>
            </Container>
            </div>
        </section>
    );
}
export default Login1;