import img1 from './Simg1.jpg';
// import img2 from './Blogimg2.png';
// import img3 from './Blogimg3.png';


const blogData= [
    {
        img: img1,
        title: "Why is it still worth it to build your own PC in 2024?",
        date: "10 August 2024"
    },
    {
        img: img1,
        title: "Greater Amberjack",
        date: "5 July 2023"
    },
    {
        img: img1,
        title: "Greater Amberjack",
        date: "5 July 2023"
    }
    
]
export default blogData;