import React from "react";
import { useState } from "react";


function Enquire(props) {

    const [userData, setUserData] = useState({
        username: "",
        email: "",
        phone: "",
        city: "",
        message: ""
    });

    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;

        setUserData({ ...userData, [name]: value })
    };

    const submitData = async (event) => {
        event.preventDefault();
        const { username, email, phone, city, message } = userData;

        if (username && email && phone && city && message) {



            const res = await fetch("https://getpc-launch-default-rtdb.firebaseio.com/UserEnquiry.json",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        username, email, phone, city, message
                    })
                }
            );
            if (res) {
                setUserData({
                    username: "",
                    email: "",
                    phone: "",
                    city: "",
                    message: ""
                })
                alert("Data Stored");
            }
            else {
                alert("Please fill the Data");
            }
        }
        else {
            alert("Please fill the Data");
        }
    };


    // const [username, setUsername] = useState('')
    // const [email, setEmail] = useState('')
    // const [phone, setPhone] = useState('')
    // const [subject, setSubject] = useState('')
    // const [city, setCity] = useState('')

    function handleLogin(e) {
        e.preventDefault()
        // Code to handle login goes here
        props.toggle()
    }

    return (
        <div className="popup">
            <div className="popup-inner">
                <div className="close-btn" onClick={props.toggle}><i class="fa-regular fa-circle-xmark"></i></div>
                <h2>ENQUIRE</h2>
                <form onSubmit={handleLogin}>
                    <label>
                        Name*
                        <input
                            type="text"
                            id="name"
                            name="username"
                            onChange={postUserData}
                            placeholder="eg. Alex"
                            value={userData.username}
                            //  onChange={e => setUsername(e.target.value)} 
                            required />
                    </label>
                    <label>
                        Email*
                        <input
                            type="text"
                            id="email"
                            name="email"
                            onChange={postUserData}
                            placeholder="myname@example.com"
                            value={userData.email}
                            //  onChange={e => setEmail(e.target.value)}
                            required />
                    </label>
                    <label>
                        Phone Number*
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            onChange={postUserData}
                            placeholder="eg. 9401188391"
                            value={userData.phone}
                            //  onChange={e => setPhone(e.target.value)}
                            required />
                    </label>
                    <label>
                        City*
                        <input
                            type="text"
                            id="city"
                            name="city"
                            onChange={postUserData}
                            placeholder="Your City"
                            value={userData.city}
                            //  onChange={e => setCity(e.target.value)}
                            required></input>
                    </label>
                    <label className="text-label">
                        Your Message*
                        <textarea
                            type="text"
                            id="message"
                            name="message"
                            onChange={postUserData}
                            value={userData.message}
                            // onChange={e => setSubject(e.target.value)}
                            required />
                    </label>
                    <button
                        className="submit-btn"
                        type="submit"
                        onClick={submitData}>Submit</button>
                </form>
            </div>
        </div>
    )
}
export default Enquire;