import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import img1 from './Gmail_icon.svg';
// import Enquire from "../../Home/HomeSec2/Enquire";
import img2 from './WhatsApp.svg';

function Contact2() {


    return (
        <section className="Contact2p">
            <Container>
                <div className="contact2-contain">
                    <span id="sp2">REACH OUT TO US!</span>

                    <div className="contact2-subcontain">
                        <div id="contact2-box">
                            {/* <img src={img1}/> */}
                            <span>Through Email</span>
                            <p>Our email consultation is simple and convenient, just click the button below, fill in your details, and tell us what you're looking for.</p>
                            <a href="mailto:support@getpc.co.in"><img id="gmail-img" src={img1} /></a>
                        </div>
                        <div id="contact2-box">
                            {/* <img src={img1}/> */}
                            <span>Through Call and Text</span>
                            <p>If you prefer speaking to someone directly, you can give us a call. Our toll free is open from 10am to 8pm, Monday to Saturday.</p>
                            <a href="tel:+919259568740" id="caller" ><i class="fa-solid fa-phone"></i>+919259568740</a>
                            <div className='whatsapp-btn-contact'>
                                <a href='https://wa.me/919259568740'><img src={img2} /></a>
                            </div>
                        </div>
                        <div id="contact2-box">
                            {/* <img src={img1}/> */}
                            <span>Visit Our Store</span>
                            <p>Visit our Haldwani store, where the service is always top-notch, unless you're into that whole "mediocre" thing.</p>
                            <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d565.1981533191771!2d79.50529878565857!3d29.221848522982416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a09b0ee03341d7%3A0x53f6112dec278fc9!2sGET%20Pc!5e0!3m2!1sen!2sin!4v1724413105750!5m2!1sen!2sin"
                                width="100%" height="200" className="border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
export default Contact2;