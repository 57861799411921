import React, { useState, useEffect } from 'react';
import { Carousel, Button, Container, Row, Col } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';
import img1 from './bgImage.png';
import img2 from '../../Home/WhatsApp.svg';
import Enquire from './Enquire';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Index() {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    const [seen, setSeen] = useState(false)

    function togglePop() {
        setSeen(!seen);
    };


    return (
        <section className="Indexp">
            <Container>
                <div>
                    <Row className='Idx1'>
                        <Col className='Itext' >
                            <span id='sp1'>WE BUILD YOUR<br />DREAM PC</span>

                            <div className='contain'>
                                <div className='text2' >
                                    <Typewriter options={{
                                        strings: ['GAMING', 'EDITING', 'CODING', 'TRADING'],
                                        autoStart: true,
                                        loop: true,
                                    }} />
                                </div>
                            </div>

                            <Button className='index-btn  px-5' onClick={togglePop} >Enquire Now</Button>
                            {seen ? <Enquire toggle={togglePop} /> : null}
                        </Col>
                        <Col data-aos="zoom-in">
                            <div className='IdxImage'>
                                <img id='imag1' src={img1} alt="Index Element" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    );

}
export default Index;