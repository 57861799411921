import React from "react";
import Navbar from "../Home/HomeSec1/Navbar";

function Aboutpage(){

    return(
        <div className="AppHome">
            <Navbar/>
            <h2 className="nonetext">Work Under Progress</h2>
        </div>
    );
}
export default Aboutpage;