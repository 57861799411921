import img1 from './CRYSTAL_X6.png';


const Picasso_Data= [
    {
        img: img1,
        model: "MODEL 1",
        cpu: "Core i7 14700k",
        m_b: "MSI Z790A Pro wifi",
        gpu: "MSI 4070 Super",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹199,000",
        old_price: "₹199,000"
    },
    {
        img: img1,
        model: "MODEL 2",
        cpu: "Core i7 12700k",
        m_b: "MSI Z790A Pro wifi",
        gpu: "MSI 4060 ti 8 GB",
        ram: "16 x 2 ADATA DDR 5",
        new_price: "₹149,000",
        old_price: "₹149,000"
    }
    
]
export default Picasso_Data;