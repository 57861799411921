import img1 from './intel.png';
import img2 from './nvidia.png';
import img3 from './amd.png';
import img4 from './gigabyte.png';
import img5 from './AntEsports.png';
import img6 from './corsair.png';
import img7 from './Deepcool.png';
import img8 from './razer.png';
import img9 from './msi.png';

function Brands() {
    return (
        <section className='Brandp'>
            <div className="slder">
                <div className="slde-track">
                    <div className="slde">
                        <img src={img1} alt='logos' height="100px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img2} alt='logos' height="120px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img3} alt='logos' height="130px" width="250px" />
                    </div>
                    <div className="slde">
                        <img src={img4} alt='logos' height="110px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img5} alt='logos' height="110px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img6} alt='logos' height="100px" width="250px" />
                    </div>
                    <div className="slde">
                        <img src={img7} alt='logos' height="100px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img8} alt='logos' height="100px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img9} alt='logos' height="100px" width="250px" />
                    </div>
                    <div className='slde'>
                        <img src={img3} alt='logos' height="130px" width="250px" />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Brands;