import React from "react";
import Navbar from '../../../src/components/Home/HomeSec1/Navbar';
import WhyUs from '../../../src/components/About/AboutSec1/WhyUs';
import Bottompage from '../../../src/components/Home/HomeLastSec/Bottompage';
import SocialMed from "../Home/HomeSec7/SocialMed";
import {Helmet} from 'react-helmet';


function Aboutpage(){

    return(
        <div className="AppHome">
            <Helmet>
                <title>About Us | GET PC</title>
            </Helmet>
            <Navbar/>
            <WhyUs/>
            <SocialMed/>
            <Bottompage/>
        </div>
    );
}
export default Aboutpage;