import React from "react";
import Navbar from '../../../src/components/Home/HomeSec1/Navbar';
import Bottompage from '../../../src/components/Home/HomeLastSec/Bottompage';
import Contact1 from "./ContactSec1/Contact1";
import Contact2 from "./ContactSec2/Contact2";
import { Helmet } from "react-helmet";

function Contactpage(){

    return(
        <section className="Contactp">
            <Helmet>
                <title>Contact Us | GET PC</title>
            </Helmet>
            <Navbar/>
            <Contact1/>
            <Contact2/>
            <Bottompage/>
        </section>
    );
}
export default Contactpage;